import { render, staticRenderFns } from "./AddPartners.vue?vue&type=template&id=078f9cef&"
import script from "./AddPartners.vue?vue&type=script&lang=js&"
export * from "./AddPartners.vue?vue&type=script&lang=js&"
import style0 from "./AddPartners.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports